import React, { Component } from "react";

export default class Header extends Component {
  render() {
    return (
      <header className="page-head header-transparent" id="home">
        <div className="rd-navbar-wrap">
          <nav
            className="rd-navbar"
            data-layout="rd-navbar-fixed"
            data-sm-layout="rd-navbar-fixed"
            data-md-layout="rd-navbar-fixed"
            data-lg-layout="rd-navbar-fixed"
            data-xl-layout="rd-navbar-static"
            data-xxl-layout="rd-navbar-static"
            data-device-layout="rd-navbar-fixed"
            data-sm-device-layout="rd-navbar-fixed"
            data-md-device-layout="rd-navbar-fixed"
            data-lg-device-layout="rd-navbar-fixed"
            data-xl-device-layout="rd-navbar-static"
            data-xxl-device-layout="rd-navbar-static"
            data-sm-stick-up-offset="50px"
            data-lg-stick-up-offset="100px"
          >
            <button
              className="rd-navbar-collapse-toggle"
              data-rd-navbar-toggle=".rd-navbar-panel-inner"
              type="submit"
            >
              <span></span>
            </button>
            <div className="bg-default">
              <div className="rd-navbar-inner">
                <div className="rd-navbar-panel">
                  <button
                    className="rd-navbar-toggle"
                    data-rd-navbar-toggle=".rd-navbar-nav-wrap"
                  >
                    <span></span>
                  </button>

                  <div className="rd-navbar-brand">
                    <a className="brand-name" href="index.html">
                      <img
                        className="brand"
                        src="/images/logo-white.png"
                        width="158"
                        height="52"
                        alt=""
                      />
                      <img
                        className="stuck-brand"
                        src="/images/cv_logo.png"
                        style={{ height: "50px", width: "auto" }}
                        alt="logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="rd-navbar-menu-panel">
                  <div className="rd-navbar-nav-wrap">
                    <ul className="rd-navbar-nav">
                      <li className="active">
                        <a href="/#home">Home</a>
                      </li>
                      <li>
                        <a href="#about">About</a>
                      </li>
                      <li>
                        <a href="#products">Products</a>
                      </li>
                      {/* <li>
                        <a href="#testimonials">Testimonials</a>
                      </li> */}
                      <li>
                        <a href="#why">Why</a>
                      </li>
                      <li>
                        <a href="#contacts">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="rd-navbar-panel-inner">
                  <div>
                    <a
                      className="btn btn-transparent btn-icon btn-icon-left"
                      href="tel:#"
                    >
                      <span className="icon fa-phone text-primary"></span>
                      <span className="text-middle">+2 010 96955787</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}
