import React, { Component } from "react";

export default class WaterHeaterProduct extends Component {
  render() {
    return (
      <div className="container ">
        <h1 className="mb-4">Solar water heating systems </h1>
        <div className="image-thumbnail mb-4">
          <img
            className="img-responsive"
            src="/images/products/solarwaterheater1.jpg"
            alt=""
            width="570"
            height="380"
          />
        </div>
        <p className="mb-2 container">
          Solar water heating systems include storage tanks and solar
          collectors. There are two types of solar water heating systems:
          active, which have circulating pumps and controls, and passive, which
          don't.
        </p>
        <br />
        <p>Active Solar Water Heating Systems</p>
        <br />
        <p>There are two types of active solar water heating systems:</p>
        <h6>Direct circulation systems</h6>
        <p>
          Pumps circulate household water through the collectors and into the
          home. They work well in climates where it rarely freezes.
        </p>
        <br />
        <h6>Indirect circulation systems</h6>
        <p>
          Pumps circulate a non-freezing, heat-transfer fluid through the
          collectors and a heat exchanger. This heats the water that then flows
          into the home. They are popular in climates prone to freezing
          temperatures.
        </p>
        <br />
        <h6>Passive Solar Water Heating Systems</h6>
        <p>
          Passive solar water heating systems are typically less expensive than
          active systems, but they're usually not as efficient. However, passive
          systems can be more reliable and may last longer. There are two basic
          types of passive systems:
        </p>
        <h6>Integral collector-storage passive systems</h6>
        <p>
          These consist of a storage tank covered with a transparent material to
          allow the sun to heat the water. Water from the tank then flows into
          the plumbing system. These work best in areas where temperatures
          rarely fall below freezing. They also work well in households with
          significant daytime and evening hot-water needs.
        </p>
        <br />

        <h6>Thermosyphon systems</h6>

        <p>
          Water is heated in a collector on the roof and then flows through the
          plumbing system when a hot water faucet is opened. The majority of
          these systems have a 40 gallon capacity.
        </p>
      </div>
    );
  }
}
