import React, { Component } from "react";

export default class SolarLightingProduct extends Component {
  render() {
    return (
      <div className="container">
        <h1 className="mb-4">Solar lighting</h1>
        <div className="image-thumbnail mb-4 d-flex">
          <img
            className="img-responsive"
            src="/images/products/solarlight1.jpg"
            alt=""
            width="570"
            height="380"
          />
        </div>

        <p className="my-4 px-5 container">
          Solar lights are made up of four main components: the solar
          photovoltaic (PV) panel, battery, control electronics and the light
          fixture. When the sun is out, a solar panel takes the light from the
          sun and produces electrical energy. The energy can then be used
          immediately or stored in a battery. The goal of most solar lights is
          to provide power at night, so they will definitely contain a battery,
          or be capable of attaching to a battery. The battery itself may not
          need to have a large capacity, due to the availability of solar
          energy, but it should be long lasting to account for difficulties
          replacing batteries in many places across the globe.
        </p>
        <div className="image-thumbnail mb-4 d-flex">
          <img
            className="img-responsive"
            src="/images/products/solarlight2.jpg"
            alt=""
            width="570"
            height="380"
          />
        </div>
      </div>
    );
  }
}
