export const PRODUCTS_ARR = [
  {
    image: "images/products/onoffgrid2.jpg",
    name: "On/off grid solar systems",
    slug: "onoff_grid"
  },
  {
    image: "images/products/solarwaterheater1.jpg",
    name: "Solar water heating systems",
    slug: "water_heater"
  },
  {
    image: "images/products/solarwaterpump1.jpg",
    name: "Solar Water Pumps",
    slug: "water_pumps"
  },
  {
    image: "images/products/swimmingsolar2.jpg",
    name: "Swimming pools solar heating systems",
    slug: "swimming_pools"
  },
  {
    image: "images/products/wallheating1.jpg",
    name: "Underfloor/wall heating systems",
    slug: "wall_heating"
  },
  {
    image: "images/products/solarlight2.jpg",
    name: "Solar lighting",
    slug: "solar_lighting"
  }
];
