import React, { Component } from "react";

export default class WhySection extends Component {
  render() {
    return (
      <section id="why">
        <div
          className="parallax-container"
          data-parallax-img="images/parallax-04.jpg"
        >
          <div className="parallax-content">
            <div className="container section-75 section-md-top-135 section-md-bottom-183 text-center">
              <div className="row justify-content-sm-center align-items-md-center">
                <div className="col-md-6 col-xl-5">
                  <h2 className="text-white">
                    <span>Why &nbsp;</span>
                    <span className="text-primary">Clear</span>
                    <span>Vision</span>?
                  </h2>
                  <p className="h6 font-default text-transform-none text-light text-white offset-top-35">
                    After years of experience and more than 500, 000 people
                    powered with the help of our solar panels, we think the
                    choice is really obvious here!
                  </p>
                  <a
                    className="btn btn-primary-transparent text-white offset-top-35 offset-sm-top-65"
                    href="#about"
                  >
                    Read more about us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
