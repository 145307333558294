import React from "react";

import OnOffGridProduct from "../products/onoff_grid";
import { Link, useParams } from "react-router-dom";
import WaterHeaterProduct from "../products/water_heater";

import WaterPumpProduct from "../products/water_pumps";
import SolarLightingProduct from "../products/solar_lighting";
import WallHeatingProduct from "../products/wall_heating";
import SwimmingPoolProduct from "../products/swimming_pools";
import ProductSection from "../components/ProductSection";
import MoreProductSection from "../components/MoreProducts";

export default function ProductDetailsPage() {
  let params = useParams();
  let productSlug = params.slug;

  return (
    <div className="" id="product-details-page">
      <Link className="btn btn-link" to="/">
        Back Home
      </Link>
      {productSlug == "onoff_grid" && <OnOffGridProduct />}
      {productSlug == "water_heater" && <WaterHeaterProduct />}
      {productSlug == "water_pumps" && <WaterPumpProduct />}
      {productSlug == "swimming_pools" && <SwimmingPoolProduct />}
      {productSlug == "wall_heating" && <WallHeatingProduct />}
      {productSlug == "solar_lighting" && <SolarLightingProduct />}
      <MoreProductSection />
    </div>
  );
}
