import "./App.css";
import Header from "./components/Header";
import ContactUsForm from "./components/ContactUs";
import HomePage from "./pages/Home";
import ProductDetailsPage from "./pages/ProductDetails";
import Footer from "./components/Footer";
import React, { Component } from "react";
import { loadScript } from "./utils";
import { BrowserRouter, Routes, Route } from "react-router-dom";
export default class App extends Component {
  componentDidMount() {
    // loadScript("./js/script.js");
  }

  render() {
    return (
      <div className="App">
        <Header />

        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/product/:slug" element={<ProductDetailsPage />} />

            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
          </Routes>
        </BrowserRouter>

        <ContactUsForm />
        <Footer />
      </div>
    );
  }
}
