import React, { Component, useRef } from "react";
import emailjs from "@emailjs/browser";

export default function ContactUsForm() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_1rjp2el",
        "template_9xxtma2",
        form.current,
        "ipyydsu0cl562JLRX"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <section className="section-top-75 section-md-top-135" id="contacts">
      <div className="container">
        <h3>Contact Us</h3>
        <hr className="hr offset-top-35" />
        <div className="row row-50 justify-content-sm-center offset-top-0 features-list-variant-2 section-top-70 section-bottom-80">
          <div className="col-sm-8 col-md-10 col-xl-6 text-md-left row-30">
            <div>
              <div className="unit unit-spacing-sm flex-column flex-md-row">
                <div className="unit-left">
                  <img
                    className="img-responsive img-rounded img-fullwidth"
                    src="images/home-08-270x180.jpg"
                    alt=""
                    width="270"
                    height="180"
                  />
                </div>
                <div className="unit-body">
                  <h6 className="offset-top-10 text-transform-none">
                    Makrm Ebid (Nasr City, CAI, Egypt)
                  </h6>
                  <p className="text-regular line-height-21 offset-top-8">
                    13 Moaz el Dawla, Floor 9{" "}
                    <br className="visible-lg-inline" /> Ciro, CA 11321
                  </p>
                  <dl className="dl-horizontal-variant-1 offset-sm-top-25 text-md-left">
                    <dt className="font-weight-bold text-dark">Phone:</dt>
                    <dd>
                      <a className="text-base text-regular" href="tel:#">
                        +20-1100828725
                      </a>
                    </dd>
                  </dl>
                  <dl className="dl-horizontal-variant-1 text-md-left">
                    <dt className="font-weight-bold text-dark">Fax:</dt>
                    <dd>
                      <a className="text-base text-regular" href="tel:#">
                        +20-26717447
                      </a>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-10 col-xl-6">
            <div className="inset-lg-left-110">
              <h5>
                Get a pricing quote for a residential or business solar panel
                installation
              </h5>

              <form
                ref={form}
                onSubmit={sendEmail}
                className="rd-mailform text-center offset-top-30"
                data-form-output="form-output-global"
                data-form-type="contact"
                method="post"
                action="bat/rd-mailform.php"
              >
                <div className="form-wrap">
                  <label className="form-label" htmlFor="contact-name">
                    Your Name
                  </label>
                  <input
                    className="form-input"
                    id="contact-name"
                    type="text"
                    name="name"
                    data-constraints="@Required"
                  />
                </div>
                <div className="form-wrap">
                  <label className="form-label" htmlFor="contact-email">
                    Email
                  </label>
                  <input
                    className="form-input"
                    id="contact-email"
                    type="email"
                    name="email"
                    data-constraints="@Email @Required"
                  />
                </div>
                <div className="form-wrap">
                  <label className="form-label" htmlFor="contact-phone">
                    Phone
                  </label>
                  <input
                    className="form-input"
                    id="contact-phone"
                    type="text"
                    name="phone"
                    data-constraints="@Numeric @Required"
                  />
                </div>
                <div className="form-wrap">
                  <select
                    className="form-input select-filter"
                    data-placeholder="Do you need a residental or commercial system?"
                    data-minimum-results-for-search="Infinity"
                    data-constraints="@Required"
                  >
                    <option>
                      Do you need a residental or commercial system?
                    </option>
                    <option value="2">Residental system</option>
                    <option value="3">Commercial system</option>
                  </select>
                </div>
                <div className="form-wrap">
                  <select
                    className="form-input select-filter"
                    data-placeholder="What is your average electricity bill?"
                    data-minimum-results-for-search="Infinity"
                    data-constraints="@Required"
                  >
                    <option>What is your average electricity bill?</option>
                    <option value="2">100</option>
                    <option value="3">200</option>
                    <option value="4">300</option>
                  </select>
                </div>
                <button className="btn btn-primary offset-top-30" type="submit">
                  GET A QUOTE
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
