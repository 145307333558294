import React, { Component } from "react";

export default class OnOffGridProduct extends Component {
  render() {
    return (
      <div className="container ">
        <h1 className="mb-4">off-grid solar system </h1>
        <div className="image-thumbnail mb-4">
          <img
            className="img-responsive"
            src="/images/products/onoffgrid2.jpg"
            alt=""
            width="570"
            height="380"
          />
        </div>
        <p className="mb-2 container">
          An off-grid solar system permits electricity to be harnessed by solar
          panels and stored inside a battery without direct connection to the
          utility grid, providing an independent power supply to your home or
          business.
        </p>
        <br />
        <p className="mb-2 container">
          Basically, an off-grid solar system is a novel innovation which
          provides you independent energy harnessed by the sun.  An off-grid
          solar system is made up of the following components.
        </p>
        <ul>
          <li>solar panels </li>
          <li>charge controllers </li>
          <li>battery bank(s) </li>
          <li>inverters </li>
        </ul>
        <p>
          The ingenuity of an off-grid solar energy system is made-up of the
          efficiency of its components. A solar energy system’s solar panels,
          charge controllers, battery bank, and inverters all work together to
          provide your laptop or refrigerator energy, and this is how.
        </p>
        <br />
        <br />
        <p>Off-grid solar energy systems work by…</p>
        <p>
          Solar Panels (PV array). Solar panels are set either on your rooftop
          or in an open yard or property space. The Sunlight is soaked up by the
          solar panels and transferred to the charge controllers. Charge
          Controllers.
        </p>
        <p>
          The charge controller is the “delivery man” between the solar panels,
          the inverters, and the battery bank. Charge controllers also act as a
          regulator, ensuring that the amount of power received through the
          solar panels does not overload the battery, instead keep the battery
          fully charged and top it off when needed. The charge controllers
          either deliver the energy directly as DC power to your lights or to
          the inverters to be converted into AC power for household appliances
          and all excess energy goes to the…
        </p>
        <p>
          Battery Bank.  With the charge controllers feeding energy to the
          batteries, the battery bank acts as the heart of the off-grid solar
          system, as it stores up excess energy for cloudy days and nights, when
          needed it pumps electricity to the…
        </p>

        <p>
          Inverters. Lastly, inverters convert the DC (direct current) power
          into AC power which is passed on to be digested by your electronics or
          appliances as DC power and allows you to switch on the TV or fry up an
          egg.  
        </p>
      </div>
    );
  }
}
