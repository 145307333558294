import React, { Component } from "react";

export default class SwimmingPoolProduct extends Component {
  render() {
    return (
      <div className="container ">
        <h1 className="mb-4">Swimming pools solar heating systems </h1>
        <div className="image-thumbnail mb-4">
          <img
            className="img-responsive"
            src="/images/products/onoffgrid2.jpg"
            alt=""
            width="570"
            height="380"
          />
        </div>
        <h6>How Solar Pool Heating Works</h6>
        <p className="mb-2 container">
          If you want to extend your swim season, lower your energy costs,
          increase your property value and shrink your carbon footprint, a solar
          pool heater is a worthwhile investment! So how do solar pool heaters
          work?
        </p>
        <p>
          The heat from the sun will always warm the water in your pool to a
          certain extent. Solar pool heaters have a unique advantage over gas
          and electric heaters since they harness the sun’s energy instead of
          using costly fuel. Our solar pool heating systems enhance this natural
          effect using your existing pump.
        </p>

        <br />
        <h6>Here’s how solar pool heating works:</h6>
        <p>
          Your pool water is directed through a series of valves to your solar
          collectors.
        </p>
        <p>
          Water enters collectors through the bottom and rises to the top
          through individual tubes.
        </p>
        <p>
          As the water gradually rises, it’s heated by the sun’s natural and
          radiant energy!
        </p>
        <p>
          The heated water returns to the pool and repeats the cycle until your
          pool has been warmed to your desired temperature.
        </p>
        <p>
          Jump in! (Or just dip your toes in, if you’re that kind of swimmer.)
        </p>
      </div>
    );
  }
}
