import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <footer className="page-footer section-top-60 section-bottom-15 section-lg-top-85 text-center">
        <div className="container">
          <div className="row justify-content-sm-center justify-content-lg-start">
            <div className="col-sm-12">
              <div className="rd-navbar-brand">
                <a className="brand-name" href="index.html">
                  <img
                    src="/images/logo-white.png"
                    width="198"
                    height="62"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="col-sm-12 offset-top-45 offset-md-top-85 text-center">
              <ul className="list-inline list-inline-xs">
                <li>
                  <a
                    target={"_blank"}
                    className="icon icon-circle icon-dark icon-xs fa-facebook"
                    href="https://web.facebook.com/clearvisionsite"
                  ></a>
                </li>

                <li>
                  <a
                    className="icon icon-circle icon-dark icon-xs fa-linkedin"
                    href="https://www.linkedin.com/company/clear-vision-site/about"
                  ></a>
                </li>
              </ul>
              <p className="font-size-12 offset-top-20">
                <span>Copyright &#169;</span>
                <span className="copyright-year"></span>
                <span className="text-white">
                  &nbsp; Clear Vision, Inc &nbsp;
                </span>
                All rights reserved. Green Tech and GT are trademarks of Clear
                Vision, Inc. &nbsp;
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
