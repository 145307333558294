import React, { Component } from "react";

import MainBanner from "../components/MainBanner";
import TeamSection from "../components/Team";
import FeaturesSection from "../components/FeaturesSection";
import WhySection from "../components/WhySection";
import ProductSection from "../components/ProductSection";
import MoreProductSection from "../components/MoreProducts";
import TestimnoialsSection from "../components/Testimnoials";
import { loadScript } from "../utils";

export default class HomePage extends Component {
  componentDidMount() {
    loadScript("./js/script.js");
  }
  render() {
    return (
      <div>
        <MainBanner />
        <TeamSection />
        <FeaturesSection />
        <ProductSection />
        <WhySection />
        <MoreProductSection />
        {/* <TestimnoialsSection /> */}
      </div>
    );
  }
}
