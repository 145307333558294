import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PRODUCTS_ARR } from "../constants";

export default class ProductSection extends Component {
  render() {
    return (
      <section className="section-60 section-md-90" id="news">
        <div className="container">
          <h3>
            <span className="wow fadeInUp duration-1">More &nbsp;</span>
            <span className="text-primary wow fadeInDown duration-1">
              Useful
            </span>
            <span className="wow fadeInUp duration-1">&nbsp; Info</span>
          </h3>
        </div>

        <div
          className="slick-slider carousel-center-mode offset-sm-top-25"
          data-arrows="true"
          data-loop="true"
          data-dots="false"
          data-swipe="false"
          data-autoplay="true"
          data-items="1"
          data-xl-items="3"
          data-center-mode="true"
          data-center-padding="0.50"
        >
          {PRODUCTS_ARR.map((x, idx) => (
            <div key={idx} className="item">
              <div className="image-thumbnail">
                <img
                  className="img-responsive"
                  src={"/" + x.image}
                  alt=""
                  width="570"
                  height="380"
                />
                <div className="caption">
                  <h5>
                    <Link to={"/product/" + x.slug}>{x.name}</Link>
                  </h5>
                  <p>
                    Absolutely! Our set of contemporary designed, low-profile
                    and premium front trim panels will integrate beautifully
                    into your home's or office building's exterior, adding a
                    stylish touch of futurism to it....
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
}
