import React, { Component } from "react";

export default class WallHeatingProduct extends Component {
  render() {
    return (
      <div className="container ">
        <h1 className="mb-4">Underfloor/wall heating systems</h1>
        <div className="image-thumbnail mb-4">
          <img
            className="img-responsive"
            src="/images/products/wallheating1.jpg"
            alt=""
            width="570"
            height="380"
          />
        </div>
        <p className="mb-2 container">
          Underfloor heating works by distributing a lower temperature of warm
          water through a circuit of pipes beneath the finished floor. Heat is
          monitored and controlled by intelligent thermostats to maintain a
          consistent temperature throughout the property or individual zones.
          Any heat source can be used including conventional boilers and
          renewable ground/air heat source pumps.
        </p>
        <h6>Key system components</h6>
        <h5>Thermostats (heating controls)</h5>
        <p>
          Individual room thermostats control when heating is required in each
          room, as well as the desired temperature. Our intelligent thermostats
          and smart underfloor heating signal the wiring centre to let it know
          when heat is needed.
        </p>
        <div className="image-thumbnail mb-4 my-4">
          <img
            className="img-responsive"
            src="/images/products/wallheating2.png"
            alt=""
            width="570"
            height="380"
          />
        </div>
        <h5>Underfloor heating control pack and manifold </h5>
        <p>
          Warm water is pumped from the heat source to the control pack and
          mixed to approx. 50°C. The control pack is fitted to the manifold that
          connects the pipe circuits installed in the floor.
        </p>

        <h5>Wiring Centre</h5>
        <p>
          The wiring centre performs central switching of zone actuators,
          control pack and boiler as signalled by the room thermostats. The
          wiring centre controls the manifold which turns the boiler on and
          sends heat to the room when required. 
        </p>

        <div className="image-thumbnail mb-4 my-3">
          <img
            className="img-responsive"
            src="/images/products/wallheating3.jpg"
            alt=""
            width="570"
            height="380"
          />
        </div>
        <h5>Pipe circuits </h5>
        <p>
          When heat is switched on the entire floor area is warmed via the pipe
          circuits to between 25°C - 28°C, providing even distribution of heat
          at slightly higher than room temperature.
        </p>
      </div>
    );
  }
}
