import React, { Component } from "react";

export default class TeamSection extends Component {
  render() {
    return (
      <section className="section-top-65 section-md-top-105" id="about">
        <div className="container">
          <h3>Who We Are</h3>
          <p className="font-size-12">Team of alternative energy specialists</p>
          <hr className="hr offset-top-45" />
          <div className="row justify-content-sm-center offset-top-40">
            <div className="col-md-11">
              <p className="h6 font-default text-transform-none text-light text-base">
                Welcome to{" "}
                <span className="text-bold">
                  <span className="text-primary">Clear</span>Vision
                </span>
                , your number one source for all things related to solar energy.
                <br />
                We're dedicated to giving you the best of solar energy projects,
                with a focus on on/off grid solar systems, solar water pumps,
                solar water heaters, swimming pool solar heating system and
                underfloor heating systems.
              </p>
              <br />
              <p className="h6 font-default text-transform-none text-light text-base">
                <span className="text-bold">
                  <span className="text-primary">Clear</span>Vision
                </span>
                ’s management have come across over 25 years of experience in
                projects across Egypt and mid-east countries, what qualifies us
                to provide projects with high feasibility and economic returns
                with the best materials and the affordable prices. ’s management
                have come across over 25 years of experience in projects across
                Egypt and mid-east countries, what qualifies us to provide
                projects with high feasibility and economic returns with the
                best materials and the affordable prices.
              </p>
              <br />
              <br />

              <p className="h6 font-default text-transform-none text-light text-base">
                If you have any questions or comments, please don't hesitate to
                contact us
                <a
                  href="mailto:a.basuny@clearvision.site"
                  className="btn btn-link"
                >
                  a.basuny@clearvision.site
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid container-fluid-custom offset-top-60 offset-md-top-110">
          <div className="row no-gutters gallery-grid">
            <div className="col-md-4">
              <img
                className="img-responsive img-fullwidth"
                src="images/about-01-639x480.jpg"
                alt=""
                width="639"
                height="480"
              />
            </div>
            <div className="col-md-4">
              <img
                className="img-responsive img-fullwidth"
                src="images/about-02-639x480.jpg"
                alt=""
                width="639"
                height="480"
              />
            </div>
            <div className="col-md-4">
              <img
                className="img-responsive img-fullwidth"
                src="images/about-03-639x480.jpg"
                alt=""
                width="639"
                height="480"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
