import React, { Component } from "react";

export default class TestimnoialsSection extends Component {
  render() {
    return (
      <section className="section-65 section-md-bottom-80" id="testimonials">
        <div className="container">
          <div className="row justify-content-sm-center">
            <div className="col-md-10 col-lg-8">
              <h3>
                <span>Our customers enjoy the sunny side</span>
                <br className="visible-lg-inline" />
                <span>of their &nbsp;</span>
                <span
                  className="text-primary wow fadeInUp duration-1"
                  data-wow-delay=".4s"
                >
                  energy consumption
                </span>
                <span>&nbsp; bills.</span>
              </h3>
              <hr className="divider divider-sm" />
            </div>
          </div>
          <div className="row row-30 justify-content-sm-center offset-top-60 offset-md-top-100">
            <div className="col-md-6 col-lg-4 view-animate fadeInLeftSm delay-06">
              <blockquote className="quote-3 text-left">
                <div className="unit flex-row unit-spacing-sm align-items-start">
                  <div className="unit-left">
                    <img
                      className="rounded-circle"
                      src="images/home-03-70x70.jpg"
                      alt=""
                      width="70"
                      height="70"
                    />
                  </div>
                  <div className="unit-body">
                    <p>
                      <cite>Markus Breitbart</cite>
                    </p>
                    <p className="font-italic text-light cite-job">
                      <span className="text-lighter">
                        Freelance Photographer
                      </span>
                      <span className="text-primary"></span>
                    </p>
                    <p className="offset-top-8 line-height-21">
                      <q>
                        I have a pretty big house that consumes a sizable amount
                        of energy each month. With the help of these solar
                        panels, I’ve been able to make great cuts to my monthly
                        bills!
                      </q>
                    </p>
                  </div>
                </div>
              </blockquote>
            </div>
            <div className="col-md-6 col-lg-4 view-animate fadeInLeftSm delay-08">
              <blockquote className="quote-3 text-left">
                <div className="unit flex-row unit-spacing-sm align-items-start">
                  <div className="unit-left">
                    <img
                      className="rounded-circle"
                      src="images/home-04-70x70.jpg"
                      alt=""
                      width="70"
                      height="70"
                    />
                  </div>
                  <div className="unit-body">
                    <p>
                      <cite>Thomas Johnson</cite>
                    </p>
                    <p className="font-italic text-light cite-job">
                      <span className="text-lighter">CEO at </span>
                      <span className="text-primary">BigInfoDev</span>
                    </p>
                    <p className="offset-top-8 line-height-21">
                      <q>
                        Installing a solar panel atop of our office building was
                        a great decision! We’ve managed to cover all our office
                        needs (which houses 50 computers).{" "}
                      </q>
                    </p>
                  </div>
                </div>
              </blockquote>
            </div>
            <div className="col-md-6 col-lg-4 view-animate fadeInLeftSm delay-1">
              <blockquote className="quote-3 text-left">
                <div className="unit flex-row unit-spacing-sm align-items-start">
                  <div className="unit-left">
                    <img
                      className="rounded-circle"
                      src="images/home-05-70x70.jpg"
                      alt=""
                      width="70"
                      height="70"
                    />
                  </div>
                  <div className="unit-body">
                    <p>
                      <cite>Jane Nicholson</cite>
                    </p>
                    <p className="font-italic text-light cite-job">
                      <span className="text-lighter">Web Designer</span>
                      <span className="text-primary"></span>
                    </p>
                    <p className="offset-top-8 line-height-21">
                      <q>
                        Everyone I dealt with at the Eco Tech company, from a
                        salesperson and office staff to the installation crew
                        has been incredibly helpful and enthusiastic.
                      </q>
                    </p>
                  </div>
                </div>
              </blockquote>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
