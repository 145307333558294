import React, { Component } from "react";

export default class WaterPumpProduct extends Component {
  render() {
    return (
      <div className="container mx-5">
        <h1 className="mb-4">Solar water pumps </h1>
        <div className="image-thumbnail mb-4">
          <img
            className="img-responsive"
            src="/images/products/onoffgrid2.jpg"
            alt=""
            width="570"
            height="380"
          />
        </div>
        <p className="mb-2 container">
          Solar water pump is driven by the electricity produced by the PV
          (photovoltaic) panels or the radiant heat generated by collected
          sunlight. This is the opposite of a diesel or grid electricity water
          pump.
        </p>

        <br />
        <br />
        <p>
          The solar powered pump uses solar energy for operation. It consists of
          a water storage tank, electrical cables, a breaker/fuse box, a DC
          water pump, a solar charge controller (MPPT), and a solar panel array.
        </p>

        <br />

        <p>
          A solar powered water pump is more efficient to operate. These pumps
          have low maintenance and installation costs.
        </p>
        <br />

        <p>
          Solar pumps have a lower environmental impact compared
          to pumps powered by IC engines or utility electricity. The pump with a
          solar system is a delightful technology with which remote areas can be
          supplied with water both ecologically and socially. Therefore, it is
          always the economic technology of choice.
        </p>

        <p>
          Remote areas tend to require mostly diesel engines and human resources
          for water supply. Solar water pumps are replacing existing pumps and
          offer many benefits such as weather and socio-economy. These pumps are
          best suitable for reservoirs and irrigation systems.
        </p>

        <p>
          The solar pumps utilize in places where grid power is not available,
          and substitutional energy sources (especially wind power) cannot
          provide enough power.
        </p>
        <br />
        <p>
          The solar powered water pump can deliver water where power lines can’t
          reach. These are commonly found in aeration, pond filtration, aquarium
          filtration, and well pumps.
        </p>
        <br />
        <p>
          These types of pumps mainly use in areas that have electricity issues.
          Otherwise, you will not have a stable power supply. This is an ideal
          water withdrawal system for green energy that combines the advantages
          of reliability, economy, and environmental protection.
        </p>
      </div>
    );
  }
}
