import React, { Component } from "react";

export default class MainBanner extends Component {
  render() {
    return (
      <section>
        <div
          className="swiper-container swiper-slider"
          data-swiper='{"autoplay":{"delay":3500},"effect":"slide"}'
        >
          <div className="swiper-wrapper">
            <div
              className="swiper-slide"
              data-slide-bg="images/products/wallheating3.jpg"
            >
              <div className="swiper-slide-caption"></div>
            </div>
            <div
              className="swiper-slide"
              data-slide-bg="images/products/solarlight2.jpg"
            >
              <div className="swiper-slide-caption"></div>
            </div>
            <div
              className="swiper-slide"
              data-slide-bg="images/products/onoffgrid1.png"
            >
              <div className="swiper-slide-caption"></div>
            </div>
          </div>
          <div className="jumbotron-custom">
            <div className="container">
              <div className="row justify-content-sm-center justify-content-xl-between align-items-lg-center">
                <div className="col-lg-6 text-left col-sm-10">
                  <div>
                    <h1>
                      <span className="text-primary view-animate fadeInUp delay-08 duration-1 animated">
                        Clear
                      </span>
                      <span className="text-white view-animate fadeInDown delay-08 duration-1 animated">
                        Vision Solar Systems
                      </span>
                    </h1>
                  </div>
                  <div className="offset-top-20 view-animate fadeInUpSmall delay-16 duration-1">
                    <p className="h6 text-white font-bold text-light text-transform-none max-w-480">
                      {" "}
                      Clear Vision is a fast-growing company in the solar energy
                      market, with business in units project development,
                      distribution and integration, and business solutions. We
                      offer a full-service portfolio for solar projects of all
                      scales that includes advisory, procurement, engineering,
                      project development, finance, and construction services.
                    </p>
                  </div>
                  <div className="offset-top-20 offset-lg-top-50 view-animate zoomInSmall delay-2 duration-1">
                    <a className="btn btn-primary" href="#about">
                      Find Out More
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-5 d-none d-lg-block">
                  <div className="bg-default section-form-slider">
                    <h5>
                      Get a pricing quote for a residential or business solar
                      panel installation
                    </h5>

                    <form
                      className="rd-mailform text-center offset-top-30"
                      data-form-output="form-output-global"
                      data-form-type="contact"
                      method="post"
                      action="bat/rd-mailform.php"
                    >
                      <div className="form-wrap">
                        <label className="form-label" htmlFor="contact-name1">
                          Your Name
                        </label>
                        <input
                          className="form-input"
                          id="contact-name1"
                          type="text"
                          name="name"
                          data-constraints="@Required"
                        />
                      </div>
                      <div className="form-wrap">
                        <label className="form-label" htmlFor="contact-email1">
                          Email
                        </label>
                        <input
                          className="form-input"
                          id="contact-email1"
                          type="email"
                          name="email"
                          data-constraints="@Email @Required"
                        />
                      </div>
                      <div className="form-wrap">
                        <label className="form-label" htmlFor="contact-phone1">
                          Phone
                        </label>
                        <input
                          className="form-input"
                          id="contact-phone1"
                          type="text"
                          name="phone"
                          data-constraints="@Numeric @Required"
                        />
                      </div>
                      <div className="form-wrap">
                        <select
                          className="form-input select-filter"
                          data-placeholder="Do you need a residental or commercial system?"
                          data-minimum-results-for-search="Infinity"
                          data-constraints="@Required"
                        >
                          <option>
                            Do you need a residental or commercial system?
                          </option>
                          <option value="2">Residental system</option>
                          <option value="3">Commercial system</option>
                        </select>
                      </div>
                      <div className="form-wrap">
                        <select
                          className="form-input select-filter"
                          data-placeholder="What is your average electricity bill?"
                          data-minimum-results-for-search="Infinity"
                          data-constraints="@Required"
                        >
                          <option>
                            What is your average electricity bill?
                          </option>
                          <option value="2">100</option>
                          <option value="3">200</option>
                          <option value="4">300</option>
                        </select>
                      </div>
                      <button
                        className="btn btn-primary offset-top-30"
                        type="submit"
                      >
                        GET A QUOTE
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="swiper-pagination"></div>
        </div>
      </section>
    );
  }
}
