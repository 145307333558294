import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PRODUCTS_ARR } from "../constants";

export default class MoreProductSection extends Component {
  render() {
    return (
      <section className="section-60 section-md-90" id="products">
        <div className="container">
          <h3>
            <span>Products &nbsp;</span>
            <span className="text-primary">Information</span>
          </h3>
          <div className="row row-30 justify-content-sm-center text-md-left">
            {PRODUCTS_ARR.map((x, idx) => (
              <div key={idx} className="col-md-6 col-lg-4">
                <div className="thumbnail-img">
                  <img
                    className="img-responsive img-fullwidth"
                    src={"/" + x.image}
                    alt=""
                    width="370"
                    height="300"
                  />
                  <div className="caption">
                    <h6 className="text-transform-none">
                      <Link className="text-dark" to={"/product/" + x.slug}>
                        {x.name}
                      </Link>
                    </h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="offset-top-40 offset-md-top-60">
            <a className="btn btn-primary" href="#">
              view all products
            </a>
          </div>
        </div>
      </section>
    );
  }
}
